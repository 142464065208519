import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import tts from './services/tts'
import './services/ambience'
import './scss/app.scss'

setTimeout(function () {
    tts.speak('You are not authorized to use this application')
}, 5000)

setTimeout(function () {
    tts.speak('We will track you down and we will put you away')
}, 14000)

setTimeout(function () {
    tts.speak('Maarten? Are you listening to what I am saying to you?')
}, 22000)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
