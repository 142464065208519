import meSpeak from 'mespeak'

meSpeak.loadConfig(require('mespeak/src/mespeak_config.json'))
meSpeak.loadVoice(require('mespeak/voices/en/en-us.json'))

export default {
    speak: function (sentence) {
        this.speak_in_voice_1(sentence)
        this.speak_in_voice_2(sentence)
        // setTimeout(function () { this.speak_in_voice_3(sentence) }.bind(this), 200)
    },
    // Voice 1
    speak_in_voice_1: function (sentence) {
        meSpeak.speak(sentence, {
            speed: 180,
            wordgap: 8,
            pitch: 0,
            variant: 'm7'
        })
    },
    // Voice 2
    speak_in_voice_2: function (sentence) {
        meSpeak.speak(sentence, {
            speed: 180,
            wordgap: 8,
            pitch: 0,
            variant: 'whisperf'
        })
    },
    // Voice 3
    speak_in_voice_3: function (sentence) {
        meSpeak.speak(sentence)
    }
}
