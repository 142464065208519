import { Howl } from 'howler'

var sound = new Howl({
    src: ['ambience.mp3'],
    autoplay: false,
    loop: true,
    volume: 0.3
})

sound.play()
